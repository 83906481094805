import classnames from "classnames"
import PropTypes from "prop-types"
import { PureComponent } from "react"
import { css } from "styled-components"
import IconButton from "../../button/IconButton"
import LoadingCircle from "../../loading/LoadingCircle"
import Pathicon from "../../pathicon/Pathicon"
import { PortableContext } from "../../portable/Portable"
import PathwrightUI from "../../ui/PathwrightUI"
import Screensize from "../../ui/Screensize"
import Text from "../../ui/Text"
import { textStyles } from "../../ui/typography"
import { WithCSS } from "../../utils/styles"

class SearchInput extends PureComponent {
  state = {
    value: ""
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.input.focus()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value })
    }
  }

  handleChange = (e) => {
    if (this.props.value == null) {
      this.setState({ value: e.target.value })
    }
    if (this.props.onChange) {
      this.props.onChange(e.target.value, e)
    }
  }

  handleKeyUp = (e) => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e.target.value, e)
    }
    if (this.props.onEnter && e.keyCode === 13) {
      this.props.onEnter(e.target.value, e)
    }

    // clear on escape key
    if (e.keyCode === 27) {
      this.handleClear()
    }
  }

  handleClear = (e) => {
    this.setState({ value: "" })
    if (this.props.onClear) {
      this.props.onClear()
    }
  }

  get value() {
    // defaulting to empty string to ensure the input value is controlled
    return this.props.value || this.state.value || ""
  }

  render() {
    const {
      typography,
      inverted,
      onEnter,
      onClear,
      className,
      style,
      icon,
      searching,
      allowFullscreen,
      ...rest
    } = this.props

    const { setPorting, porting } = this.context || {}

    return (
      <PathwrightUI.Consumer contrast={1}>
        {({
          boxShadow,
          borderColor,
          borderRadius,
          backgroundColor,
          emphasis
        }) => (
          <Screensize.Consumer>
            {(screensize) => {
              const typeStyles = textStyles({ typography, screensize })
              const inputColor = Text.getColor({ emphasis, inverted, backgroundColor }) // prettier-ignore
              const placeholderColor = Text.getColor({ emphasis, inverted, backgroundColor }) // prettier-ignore
              const spacing = "0.5em"

              const iconProps = {
                color: placeholderColor,
                size: 16,
                style: {
                  margin: `${spacing}`
                }
              }

              return (
                <WithCSS
                  className={classnames("SearchInput")}
                  css={css`
                    ${style}
                    display: grid;
                    grid-template-columns: max-content 1fr max-content;
                    align-items: center;
                    background-color: ${backgroundColor};
                    border-radius: 50px;
                  `}
                >
                  <WithCSS
                    as={Pathicon}
                    icon="search"
                    css={css`
                      ${typeStyles}
                      margin: ${spacing};
                      color: ${placeholderColor};
                    `}
                  />
                  <WithCSS
                    as="input"
                    {...rest}
                    ref={(ref) => (this.input = ref)}
                    value={this.value}
                    onKeyUp={this.handleKeyUp}
                    onChange={this.handleChange}
                    css={css`
                      ${typeStyles}
                      border: none;
                      outline: none;
                      background: none;
                      padding-top: ${spacing};
                      padding-bottom: ${spacing};
                      fontweight: normal;
                      color: ${inputColor};
                      &::placeholder: {
                        color: ${placeholderColor};
                      }
                    `}
                    className={classnames(className)}
                  />
                  {searching ? (
                    <LoadingCircle {...iconProps} center={false} />
                  ) : onClear && this.value ? (
                    <IconButton
                      {...iconProps}
                      icon="x"
                      onClick={this.handleClear}
                    />
                  ) : setPorting && allowFullscreen ? (
                    porting ? (
                      <IconButton
                        {...iconProps}
                        className="Fullscreen__minimize"
                        icon="minimize"
                        onClick={() => setPorting(false)}
                      />
                    ) : (
                      <IconButton
                        {...iconProps}
                        className="Fullscreen__maximize"
                        icon="maximize"
                        onClick={() => setPorting(true)}
                      />
                    )
                  ) : null}
                </WithCSS>
              )
            }}
          </Screensize.Consumer>
        )}
      </PathwrightUI.Consumer>
    )
  }
}

SearchInput.displayName = "SearchInput"

SearchInput.contextType = PortableContext

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  autoFocus: PropTypes.bool,
  searching: PropTypes.bool
}

SearchInput.defaultProps = {
  placeholder: "Search...",
  icon: "search",
  autoFocus: true,
  searching: false,
  allowFullscreen: false
}

export default SearchInput
