import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { getContentToken } from "./permissions"

const getGQLHost = () =>
  process.env.REACT_APP_GQL || window.BLOCKS_GRAPHQL_HOST || null

const PORT = process.env.REACT_APP_SERVER_PORT || 8000

export const getLocalEndpoint = (accountID) => {
  let domain = getGQLHost()
  let isDev = domain === null || domain.indexOf("localhost") > -1
  return !isDev
    ? `https://${domain}/blocks/graphql?school_id=${accountID}`
    : `http://localhost:${PORT}/blocks/graphql?school_id=${accountID}`
}

let _clientsCache = {}
export const getApolloClient = ({ endpoint = null, accountID = null }) => {
  if (!endpoint)
    endpoint = getLocalEndpoint(
      accountID || localStorage.getItem("school_id") || 3
    )

  const authToken = localStorage.getItem("auth_token")

  const blocksToken = localStorage.getItem("blocks_token")

  const cacheKey = [endpoint, authToken, blocksToken].filter(Boolean).join("-")

  // https://www.apollographql.com/docs/link/links/retry
  // Default is 5 attempts
  const retryLink = new RetryLink()

  const middlewareLink = setContext((req, prevContext) => {
    const { variables } = req
    let headers = {}
    if (authToken) {
      headers.authorization = `Bearer ${authToken}`
    }
    const contextKey = variables.contextKey
    const mode = variables.mode
    if (contextKey && mode) {
      const contentToken = getContentToken(contextKey, mode)
      if (contentToken) {
        headers["X-Blocks-Access-Token"] = contentToken
      }
    } else {
      // console.log("could not cache access token because of missing data: ", {
      //   contextKey,
      //   mode
      // })
    }

    return { headers }
  })

  const httpLink = createHttpLink({
    uri: (operation) => {
      let uri = endpoint

      // Supply an operationName as a convenience for identifying the operation
      // via the endpoint, which, for instance, is all that is included in
      // Sentry breadcrumbs. So, this can help with debugging.
      if (operation.operationName) {
        uri += `&operationName=${operation.operationName}`
      }

      return uri
    }
  })

  const resetToken = onError(
    ({ networkError, graphQLErrors, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )

      if (networkError && networkError.statusCode === 401) {
        // remove cached token on 401 from the server
        console.log(
          "recieved 401 from server so clearing blocks token and re-trying"
        )
        localStorage.removeItem("blocks_token")
        return forward(operation)
      }
    }
  )

  _clientsCache[cacheKey] ||= new ApolloClient({
    link: from([retryLink, middlewareLink, resetToken, httpLink]), //  middlewareLink.concat(httpLink).concat(resetToken),
    cache: new InMemoryCache({
      typePolicies: {
        // Query: {
        //   fields: {
        //     content: {
        //       keyArgs: (_, { variables }) => {
        //         const { contextKey, id } = variables
        //         return contextKey
        //       }
        //     }
        //   }
        // },
        Content: {
          fields: {
            blocks: {
              merge(existing, incoming) {
                return incoming
              },
              keyArgs: (_, { variables }) => {
                const { mode, userID } = variables
                return `${mode}:${userID}`
              }
            }
          }
        }
      }
    })
  })

  return _clientsCache[cacheKey]
}

export default getApolloClient
