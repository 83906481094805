import Alert from "@pathwright/ui/src/components/alert/Alert"
import Button from "@pathwright/ui/src/components/button/Button"
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
import RadioButton from "@pathwright/ui/src/components/form/form-radio/RadioButton"
import RadioGroup from "@pathwright/ui/src/components/form/form-radio/RadioGroup"
import {
  useInitialValues,
  validate
} from "@pathwright/ui/src/components/form/utils"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import PickerDropdown from "@pathwright/ui/src/components/picker/PickerDropdown"
import { PickerItem } from "@pathwright/ui/src/components/picker/PickerList"
import Text from "@pathwright/ui/src/components/ui/Text"
import LibraryPickerDropdown from "@pathwright/web/src/modules/library/picker/LibraryPickerDropdown"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { Formik, useFormikContext } from "formik"
import get from "lodash/get"
import { useEffect, useState } from "react"
import CATEGORIES_QUERY from "../../../category/graphql/categories-query"
import { usePathwrightContext } from "../../../pathwright/PathwrightContext"
import { flattenEdges, usePaginator } from "../../../utils/apollo"

const SelectResources = ({ picking, togglePicking }) => {
  const { t } = useTranslate()
  const { values, setValues, setFieldTouched } = useFormikContext()

  return (
    <div style={{ position: "relative", paddingBottom: "15px" }}>
      {values.selectedResourceIds.length ? (
        <div>
          {t("{{ count }} course selected", {
            defaultValue_plural: "{{ count }} courses selected",
            count: values.selectedResourceIds.length
          })}
          {" • "}
          <Button styleType="text" brand onClick={togglePicking}>
            {t("view or edit")}
          </Button>
        </div>
      ) : (
        <div>
          {t("No courses selected")}
          {" • "}
          <Button styleType="text" brand onClick={togglePicking}>
            {t("select courses")}
          </Button>
        </div>
      )}

      {picking && (
        <LibraryPickerDropdown
          prompt={t("Check the Courses the code applies to")}
          selectedPickerItemIds={values.selectedResourceIds}
          onPickMultiple={(selectedResourceIds) => {
            setFieldTouched("selectedResourceIds")
            setValues({ ...values, selectedResourceIds })
            togglePicking()
          }}
          onOutsideClick={togglePicking}
          first={1042}
          fetchPolicy="cache-first"
          renderPickerItem={(props) => {
            const { pickerItem } = props

            // Supply the resource ID as the meta, mainly for differentiating
            // between resources with the same name (and image).
            return (
              <PickerItem
                {...props}
                pickerItem={{
                  ...pickerItem,
                  meta: `(${pickerItem.id})`
                }}
              />
            )
          }}
        />
      )}
    </div>
  )
}

const useCategories = ({
  first,
  search,
  filterBySelectedPickerItemIds,
  selectedPickerItemIds
}) => {
  const { school } = usePathwrightContext()

  const query = useQuery(CATEGORIES_QUERY, {
    variables: {
      first,
      search,
      school_id: school.id,
      ids: filterBySelectedPickerItemIds
        ? {
            in: selectedPickerItemIds
          }
        : null
    }
  })

  const categories = flattenEdges(
    get(query.data, "context.school.categories", [])
  )
  const pickerItems = categories.map((category) => ({
    id: category.id,
    name: category.name
  }))

  const { loading } = query
  const { loadingMore, loadMore, hasMore } = usePaginator({
    data: query, // hacky
    path: "context.school.categories"
  })

  return {
    pickerItems,
    loading,
    loadingMore,
    loadMore,
    hasMore
  }
}

const SelectCategories = ({ picking, togglePicking }) => {
  const { t } = useTranslate()
  const { values, setValues, setFieldTouched } = useFormikContext()

  return (
    <div style={{ position: "relative", paddingBottom: "15px" }}>
      {values.selectedCategoryIds.length ? (
        <div>
          {t("{{ count }} catetory selected", {
            defaultValue_plural: "{{ count }} catetories selected",
            count: values.selectedCategoryIds.length
          })}
          {" • "}
          <Button styleType="text" brand onClick={togglePicking}>
            {t("view or edit")}
          </Button>
        </div>
      ) : (
        <div>
          {t("No catetories selected")}
          {" • "}
          <Button styleType="text" brand onClick={togglePicking}>
            {t("select catetories")}
          </Button>
        </div>
      )}

      {picking && (
        <PickerDropdown
          prompt={t("Check the Categories the code applies to")}
          selectedPickerItemIds={values.selectedCategoryIds}
          usePickerQuery={useCategories}
          onPickMultiple={(selectedCategoryIds) => {
            setFieldTouched("selectedCategoryIds")
            setValues({ ...values, selectedCategoryIds })
            togglePicking()
          }}
          onOutsideClick={togglePicking}
        />
      )}
    </div>
  )
}

const ManageDiscountResourcesForm = ({ setValues }) => {
  const { tc } = useTranslate()
  const [picking, setPicking] = useState(false)
  const togglePicking = () => setPicking((picking) => !picking)

  // values from parent form
  const { values } = useFormikContext()

  useEffect(() => {
    if (
      values.targetType === "selectResources" &&
      !values.selectedResourceIds.length &&
      !picking
    ) {
      setPicking(true)
    }
  }, [values.targetType])

  return (
    <Formik
      initialValues={useInitialValues(
        ManageDiscountResourcesForm.initialValues
      )}
      validate={(values) => {
        setValues(values)
        // return the errors
        return ManageDiscountResourcesForm.validate(values)
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <CardBlock paddingTop={false} paddingBottom={false}>
          <CardBlock paddingLeft={false} paddingRight={false}>
            <RadioGroup hideStatus topLabel label={tc("apply discount to:")}>
              <RadioButton
                name="targetType"
                value="allResources"
                checked={values.targetType === "allResources"}
                required
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Text.Body>{tc("all current & future courses")}</Text.Body>
              </RadioButton>

              <RadioButton
                name="targetType"
                type="radio"
                value="selectResources"
                checked={values.targetType === "selectResources"}
                required
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <Text.Body>{tc("select courses")}</Text.Body>
              </RadioButton>

              {false && (
                <RadioButton
                  name="targetType"
                  type="radio"
                  value="selectCategories"
                  checked={values.targetType === "selectCategories"}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <Text.Body>{tc("select categories")}</Text.Body>
                </RadioButton>
              )}
            </RadioGroup>

            {values.targetType === "selectResources" && (
              <SelectResources
                picking={picking}
                togglePicking={togglePicking}
              />
            )}
            {values.targetType === "selectCategories" && (
              <SelectCategories
                picking={picking}
                togglePicking={togglePicking}
              />
            )}
            {Boolean(
              errors.targetType &&
                touched.targetType &&
                touched.selectedResourceIds
            ) && <Alert error={errors.targetType} />}
          </CardBlock>
        </CardBlock>
      )}
    </Formik>
  )
}

ManageDiscountResourcesForm.displayName = "ManageDiscountResourcesForm"

ManageDiscountResourcesForm.initialValues = {
  targetType: "allResources",
  selectedResourceIds: [],
  selectedCategoryIds: []
}

ManageDiscountResourcesForm.validate = validate((key, value, values) => {
  switch (key) {
    case "targetType":
      switch (value) {
        case "selectResources":
          if (!values.selectedResourceIds.length)
            return "Must select at least 1 course"
          break
        case "selectCategories":
          if (!values.selectedCategoryIds.length)
            return "Must select at least 1 category"
          break
      }
  }
})

export default ManageDiscountResourcesForm
