import {
  Alert,
  AlertTitle,
  Box,
  Button,
  HStack,
  Input,
  VStack
} from "@chakra-ui/react"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useQueryParam } from "use-query-params"
import { z } from "zod"
import { toFormikValidate } from "zod-formik-adapter"
import Pathicon from "../pathicon/Pathicon"

const schema = z.object({
  code: z.string().min(1)
})
const validate = toFormikValidate(schema)

function OrderDiscountForm({ applyDiscountCode, searchParamKey }) {
  const { t } = useTranslate()
  const [discountCode] = useQueryParam(searchParamKey)
  const [discountResult, setDiscountResult] = useState(null)
  const [discountError, setDiscountError] = useState(null)
  const [discountFormVisible, setDiscountFormVisible] = useState(false)

  async function handleDiscount(discountCode) {
    setDiscountError(null)
    const discountResult = await applyDiscountCode(discountCode)
    if (discountResult) {
      setDiscountResult(discountResult)
      setDiscountFormVisible(false)
    } else {
      setDiscountError(`The code "${discountCode}" is not valid.`)
      // ensure discount form is visibile (especially in the case where a discount is auto queried due to code in search params)
      setDiscountFormVisible(true)
    }
  }

  function submitDiscount({ code }) {
    return handleDiscount(code)
  }

  useEffect(() => {
    // on initial mount, let's query for the discount if there is one pulled from the URL search params
    if (discountCode) {
      handleDiscount(discountCode)
    }
  }, [])

  const discountSuccess = discountResult
    ? typeof discountResult === "string"
      ? discountResult
      : discountResult.discount_percent &&
        discountResult.currency_display_discount_price
      ? `${t("payments.elements_form.discount_applied", {
          perc: discountResult.discount_percent * 100,
          newTotal: discountResult.currency_display_discount_price
        })}.`
      : discountResult.discount_perc &&
        discountResult.discounted_total_currency_display
      ? `${t("payments.elements_form.discount_applied", {
          perc: discountResult.discount_perc * 100,
          newTotal: discountResult.discounted_total_currency_display
        })}.`
      : discountResult.discountMessage
      ? discountResult.discountMessage
      : null
    : null

  return (
    <VStack w="100%">
      <Button
        colorScheme="brand"
        variant="link"
        onClick={() => setDiscountFormVisible((x) => !x)}
        mr="auto"
        cursor="pointer"
        bg="transparent"
      >
        {discountFormVisible
          ? t("Cancel")
          : discountResult
          ? t("Try a different code?")
          : t("payments.elements_form.have_a_coupon")}
      </Button>
      {discountFormVisible ? (
        <VStack w="100%">
          <Formik
            initialValues={{ code: discountCode || "" }}
            onSubmit={submitDiscount}
            validate={validate}
            validateOnMount
          >
            {(form) => (
              <Form style={{ width: "100%" }}>
                <HStack w="100%">
                  <Field
                    as={Input}
                    name="code"
                    placeholder="Enter your discount code"
                    value={discountCode}
                    flexGrow={1}
                    autoFocus
                  />

                  <Button
                    colorScheme="brand"
                    type="submit"
                    isDisabled={!form.isValid || form.isSubmitting}
                    isLoading={form.isSubmitting}
                    cursor="pointer"
                  >
                    {t("Apply Code")}
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
          {!!discountError && (
            <Alert status="error" variant="subtle" wordBreak="break-word">
              <Pathicon icon="caution-octagon" mr={4} />
              <Box>
                <AlertTitle>{discountError}</AlertTitle>
              </Box>
            </Alert>
          )}
        </VStack>
      ) : discountSuccess ? (
        <Alert status="success" variant="subtle" wordBreak="break-word">
          <Pathicon icon="check-square" mr={4} />
          <Box>
            <AlertTitle>{discountSuccess}</AlertTitle>
          </Box>
        </Alert>
      ) : null}
    </VStack>
  )
}

OrderDiscountForm.displayName = "OrderDiscountForm"

OrderDiscountForm.defaultProps = {
  searchParamKey: "coupon"
}

export default OrderDiscountForm
