import { useScreensizeContext } from "@pathwright/ui/src/components/ui/Screensize"
import PropTypes from "prop-types"
import styled from "styled-components"
import IconButton from "../button/IconButton"
import InlineConfirm from "../confirm/InlineConfirm"
import Image from "../image/Image"
import Pathicon from "../pathicon/Pathicon"
import PathwrightUI from "../ui/PathwrightUI"
import Text from "../ui/Text"
import { SECONDARY_GRAY } from "../utils/colors"
import { clampFileName, humanFileSize } from "./utils"

const borderRadius = "5px"

export const StyledAttachmentWrapper = styled.div`
  color: ${SECONDARY_GRAY};
  position: relative;
  border-radius: ${borderRadius};
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 55px;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .Attachment__content {
    .ql-editor p,
    BlockText {
      overflow-wrap: anywhere;
    }

    & > span {
      display: flex;
      align-items: center;
    }

    & > span:first-of-type {
      margin-right: 0;
      width: 100%;
    }

    i {
      font-size: 1.2em;
    }

    .Attachment__content-meta {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin: 0 0 0 5px;

      .BlockText {
        overflow-wrap: anywhere;
        ${(p) =>
          p.mode === "view"
            ? `
          cursor: pointer;
        `
            : ``}
      }

      /* Override browser link styles */
      a {
        color: #292e35;
      }

      & > span {
        margin: 0;
      }

      .Attachment__content-name {
        color: #292e35;
      }

      .Attachment__content-filesize {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  &:hover {
    .IconButton {
      visibility: visible;
    }
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    i,
    span {
      margin: 5px;
      color: rgba(0, 0, 0, 0.6);
    }

    img {
      max-height: 34px;
      border-radius: 3px;
    }
  }

  .IconButton {
    color: ${SECONDARY_GRAY};
    visibility: ${(p) => (p.isMobile ? "visible" : "hidden")};
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    transform: translate(-50%, -50%);

    i {
      font-size: 20px;
    }
  }

  .InlineConfirm {
    border-radius: ${borderRadius};
  }

  .Attachment__actions {
    margin: 0;

    a {
      padding: 1.5rem 0.7rem;
    }
  }
`

const iconMap = {
  image: "file-image",
  audio: "file-audio",
  video: "file-video"
}

const AttachmentThumbnail = ({
  url,
  mimetype = "",
  thumbnail,
  color,
  altText
}) => {
  const icon = Object.values(iconMap).find((v) =>
    Object.keys(iconMap).find(
      (k) => iconMap[k] === v && mimetype.search(k) > -1
    )
  )

  if (!thumbnail && mimetype.search("image") > -1) {
    thumbnail = url
  }

  return thumbnail ? (
    <span className="Attachment__thumbnail">
      <Image
        className="Attachment__thumbnail-img"
        src={thumbnail}
        alt={altText}
      />
    </span>
  ) : (
    <span className="Attachment__thumbnail" aria-label={altText}>
      <Pathicon
        className="Attachment__thumbnail-icon"
        icon={icon || "file-text"}
        style={{ color }}
      />
    </span>
  )
}

const Attachment = (props) => {
  const {
    mimetype,
    url,
    download,
    downloadOnly,
    thumbnail,
    overlay,
    onRemove,
    iconStyles,
    truncate,
    fileSize,
    renderNameEditor,
    renderNameView,
    mode,
    isLink,
    name,
    requireConfirmation
  } = props

  const truncateLength = typeof truncate === "number" ? truncate : 14
  const readyName = truncate
    ? clampFileName(name, { isFile: false, len: truncateLength })
    : name

  const isMobile = useScreensizeContext() === "sm"

  return (
    <PathwrightUI.Consumer>
      {({ primaryBrandColor }) => (
        <InlineConfirm overlay={overlay} onConfirm={onRemove} stack>
          {({ inlineConfirmNode, confirm }) => {
            return (
              <StyledAttachmentWrapper
                className="Attachment"
                themeColor={primaryBrandColor}
                // href={!download || downloadOnly ? url : null}
                // target={downloadOnly && !isLink ? "" : "_blank"}
                isMobile={isMobile}
                mode={mode}
                onClick={(e) => {
                  if (!download || downloadOnly) {
                    e.preventDefault()
                    window.open(url, "_blank")
                  }
                }}
              >
                <Text.Meta className="Attachment__content">
                  <span>
                    <AttachmentThumbnail
                      url={url}
                      mimetype={mimetype}
                      thumbnail={thumbnail}
                      color={primaryBrandColor}
                      altText={`file thumbnail for ${readyName}`}
                    />
                    <span className="Attachment__content-meta">
                      {renderNameEditor && mode === "edit" ? (
                        renderNameEditor({ name: readyName })
                      ) : renderNameView && mode === "view" ? (
                        renderNameView({ name: readyName })
                      ) : (
                        <span className="Attachment__content-name">
                          {readyName}
                        </span>
                      )}
                      {fileSize ? (
                        <span
                          className="Attachment__content-filesize"
                          aria-label={`File size: ${humanFileSize(
                            fileSize,
                            true
                          )}`}
                        >
                          {humanFileSize(fileSize, true)}
                        </span>
                      ) : null}
                    </span>
                  </span>
                  <span className="Attachment__actions">
                    {download && (
                      <a download href={url} aria-label={`Download file`}>
                        <Pathicon icon="download" />
                      </a>
                    )}
                  </span>
                </Text.Meta>
                {onRemove && !inlineConfirmNode && (
                  <IconButton
                    ariaLabel="Remove attachment"
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      if (requireConfirmation) {
                        confirm()
                      } else {
                        onRemove()
                      }
                    }}
                    className="Attachment__remove-button"
                    icon="plus-circle"
                    rotate={45}
                    style={iconStyles}
                  />
                )}
                {inlineConfirmNode}
              </StyledAttachmentWrapper>
            )
          }}
        </InlineConfirm>
      )}
    </PathwrightUI.Consumer>
  )
}

Attachment.propTypes = {
  name: PropTypes.string.isRequired,
  mimetype: PropTypes.string.isRequired,
  fileSize: PropTypes.number, // bytes
  thumbnail: PropTypes.string,
  url: PropTypes.string.isRequired,
  download: PropTypes.bool,
  downloadOnly: PropTypes.bool,
  onRemove: PropTypes.func,
  truncate: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  mode: PropTypes.oneOf(["view", "edit"]),
  iconStyles: PropTypes.object,
  loadingURL: PropTypes.bool, // Currently for Mux videos that load a download url
  renderNameEditor: PropTypes.func, // This and func below allows for editable names
  renderNameView: PropTypes.func,
  isLink: PropTypes.bool,
  requireConfirmation: PropTypes.bool
}

Attachment.defaultProps = {
  download: true, // Default: attachments used for downloading files
  downloadOnly: true, // Default: attachments used only for downloading files
  truncate: true,
  mode: "view",
  requireConfirmation: true
}

export default Attachment
